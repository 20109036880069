import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle57 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Tariefverhoging Waalse tol per 2025

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage57.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                03-10-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            Wallonië{" "}
            </span>
            voert per{" "}
            <span style={{color: "#9c29ff"}}>
            1 januari 2025{" "}
            </span>
            een verhoging door in de tarieven voor de kilometerheffing voor vrachtwagens. Deze nieuwe tarieven zijn ingevoerd om de stijgende kosten van{" "}
            <span style={{color: "#2b5baa"}}>
            materialen en arbeidskrachten{" "}
            </span>
            in België te compenseren, twee essentiële factoren voor het onderhoud en herstel van de wegeninfrastructuur. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf 2025 betalen vrachtwagens met een toegelaten maximaal gewicht van 3,5 tot 12 ton tussen{" "}
            <span style={{color: "#2b5baa"}}>
            € 0,055 (Euro 6){" "}
            </span>
            en{" "}
            <span style={{color: "#2b5baa"}}>
            € 0,111 (Euro 0){" "}
            </span>
            per kilometer. Voor vrachtwagens van 12 tot 32 ton bedragen de tarieven tussen{" "}
            <span style={{color: "#9c29ff"}}>
            € 0,160{" "}
            </span>
            en{" "}
            <span style={{color: "#9c29ff"}}>
            € 0,216{" "}
            </span>
            per kilometer, terwijl voertuigen met een gewicht van meer dan 32 ton tussen{" "}
            <span style={{color: "#2b5baa"}}>
            € 0,191{" "}
            </span>
            en{" "}
            <span style={{color: "#2b5baa"}}>
            € 0,247{" "}
            </span>
            per kilometer betalen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De software van de{" "}
            <span style={{color: "#9c29ff"}}>
            On Board Units (OBU’s){" "}
            </span>
            van de zes geaccrediteerde dienstverleners voor de kilometerheffing in België – Axxès, Satellic, Telepass, Toll4Europe, TotalEnergies Marketing Services en W.A.G. Payment Solutions – zal {" "}
            <span style={{color: "#9c29ff"}}>
            automatisch worden bijgewerkt met de nieuwe tarieven.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            <span style={{color: "#2b5baa"}}>
            Sofico,{" "} 
            </span>
            de beheerder van het Waalse wegennetwerk,{" "}  
            <span style={{color: "#2b5baa"}}>
            herinvesteert de inkomsten{" "}
            </span>
            uit de tolheffingen volledig{" "}
            <span style={{color: "#2b5baa"}}>
            in het onderhoud en de renovatie van de regionale wegen in Wallonië. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
              
              <div style={{ position: "relative" }}>
                <CardMedia
                
                  component="img"
                  src={require("../../assets/NewsImageSoficoWalloniePer01_01_2025.png")}
                  alt="news image"
                  maxWidth="1119px"
                 
                />
              </div>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle57;

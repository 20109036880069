import React from "react";
import { Route, Routes } from "react-router-dom";
// import NormalRefund from "./components/pages/NormalRefund";
// import FlexibleRefund from "./components/pages/FlexibleRefund";
// import FastRefund from "./components/pages/FastRefund";
// import ExciseDuties from "./components/pages/ExciseDuties";
import DeltaPetrol from "./components/pages/DeltaPetrol";
import DeltaHybrid from "./components/pages/DeltaHybrid";
import DeltaElectric from "./components/pages/DeltaElectric";
import DeltaDiesel from "./components/pages/DeltaDiesel";
import Homepage from "./components/pages/Homepage";
import PricingCalculation from "./components/pages/PricingCalculation";
import OverDelta from "./components/pages/OverDelta";
import FAQ from "./components/pages/FAQ";
import Contact from "./components/pages/Contact";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

// import Navbarmenu from "./components/menu/Navbarmenu";
import News from "./components/pages/News";
import Footer from "./components/Footer";
import DieselRates from "./components/pages/DieselRates";
import NewsDetails from "./components/pages/NewsDetails";
import NewsDetails2 from "./components/pages/NewsDetails2";
import NewsDetails3 from "./components/pages/NewsDetails3";
import NewsDetails4 from "./components/pages/NewsDetails4";
import NewsDetails5 from "./components/pages/NewsDetails5";
import NewsDetails6 from "./components/pages/NewsDetails6";
import NewsDetails7 from "./components/pages/NewsDetails7";
import NewsDetails8 from "./components/pages/NewsDetails8";
import NewsDetails9 from "./components/pages/NewsDetails9";
import NewsDetails10 from "./components/pages/NewsDetails10";
import NewsDetails11 from "./components/pages/NewsDetails11";
import NewsDetails12 from "./components/pages/NewsDetails12";
import NewsDetails13 from "./components/pages/NewsDetails13";
import NewsDetails14 from "./components/pages/NewsDetails14";
import NewsDetails15 from "./components/pages/NewsDetails15";
import NewsDetails16 from "./components/pages/NewsDetails16";
import NewsDetails17 from "./components/pages/NewsDetails17";
import NewsDetails18 from "./components/pages/NewsDetails18";
import NewsDetails19 from "./components/pages/NewsDetails19";
import NewsDetails20 from "./components/pages/NewsDetails20";
import NewsDetails21 from "./components/pages/NewsDetails21";
import NewsDetails22 from "./components/pages/NewsDetails22";
import NewsDetails23 from "./components/pages/NewsDetails23";
import NewsDetails24 from "./components/pages/NewsDetails24";
import NewsDetails25 from "./components/pages/NewsDetails25";
import NewsDetails26 from "./components/pages/NewsDetails26";
import NewsDetails27 from "./components/pages/NewsDetails27";
import NewsDetails28 from "./components/pages/NewsDetails28";
import NewsDetails29 from "./components/pages/NewsDetails29";
import NewsDetails30 from "./components/pages/NewsDetails30";
import NewsDetails31 from "./components/pages/NewsDetails31";
import NewsDetails32 from "./components/pages/NewsDetails32";
import NewsDetails33 from "./components/pages/NewsDetails33";
import NewsDetails34 from "./components/pages/NewsDetails34";
import NewsDetails35 from "./components/pages/NewsDetails35";
import NewsDetails36 from "./components/pages/NewsDetails36";
import NewsDetails37 from "./components/pages/NewsDetails37";
import NewsDetails38 from "./components/pages/NewsDetails38";
import NewsDetails39 from "./components/pages/NewsDetails39";
import NewsDetails40 from "./components/pages/NewsDetails40";
import NewsDetails41 from "./components/pages/NewsDetails41";
import NewsDetails42 from "./components/pages/NewsDetails42";
import NewsDetails43 from "./components/pages/NewsDetails43";
import NewsDetails44 from "./components/pages/NewsDetails44";
import NewsDetails45 from "./components/pages/NewsDetails45";
import NewsDetails46 from "./components/pages/NewsDetails46";
import NewsDetails47 from "./components/pages/NewsDetails47";
import NewsDetails48 from "./components/pages/NewsDetails48";
import NewsDetails49 from "./components/pages/NewsDetails49";
import NewsDetails50 from "./components/pages/NewsDetails50";
import NewsDetails51 from "./components/pages/NewsDetails51";
import NewsDetails52 from "./components/pages/NewsDetails52";
import NewsDetails53 from "./components/pages/NewsDetails53";
import NewsDetails54 from "./components/pages/NewsDetails54";
import NewsDetails55 from "./components/pages/NewsDetails55";
import NewsDetails56 from "./components/pages/NewsDetails56";
import NewsDetails57 from "./components/pages/NewsDetails57";
import NewsDetails58 from "./components/pages/NewsDetails58";
import NewsDetails59 from "./components/pages/NewsDetails59";
import LandingConfirmation from "./components/pages/LandingConfirmation";
// import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <ResponsiveAppBar />
      {/* <Navbarmenu /> */}
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="Home" element={<Homepage />} />
        <Route path="DeltaPetrol" element={<DeltaPetrol />} />
        <Route path="DeltaHybrid" element={<DeltaHybrid />} />
        <Route path="DeltaElectric" element={<DeltaElectric />} />
        <Route path="DeltaDiesel" element={<DeltaDiesel />} />
        <Route path="DieselRates" element={<DieselRates />} />
        <Route path="pricingCalculation" element={<PricingCalculation />} />
        <Route path="OverDelta" element={<OverDelta />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="News" element={<News />} />
        <Route path="article1" element={<NewsDetails />} />
        <Route path="article2" element={<NewsDetails2 />} />
        <Route path="article3" element={<NewsDetails3 />} />
        <Route path="article4" element={<NewsDetails4 />} />
        <Route path="article5" element={<NewsDetails5 />} />
        <Route path="article6" element={<NewsDetails6 />} />
        <Route path="article7" element={<NewsDetails7 />} />
        <Route path="article8" element={<NewsDetails8 />} />
        <Route path="article9" element={<NewsDetails9 />} />
        <Route path="article10" element={<NewsDetails10 />} />
        <Route path="article11" element={<NewsDetails11 />} />
        <Route path="article12" element={<NewsDetails12 />} />
        <Route path="article13" element={<NewsDetails13 />} />
        <Route path="article14" element={<NewsDetails14 />} />
        <Route path="article15" element={<NewsDetails15 />} />
        <Route path="article16" element={<NewsDetails16 />} />
        <Route path="article17" element={<NewsDetails17 />} />
        <Route path="article18" element={<NewsDetails18 />} />
        <Route path="article19" element={<NewsDetails19 />} />
        <Route path="article20" element={<NewsDetails20 />} />
        <Route path="article21" element={<NewsDetails21 />} />
        <Route path="article22" element={<NewsDetails22 />} />
        <Route path="article23" element={<NewsDetails23 />} />
        <Route path="article24" element={<NewsDetails24 />} />
        <Route path="article25" element={<NewsDetails25 />} />
        <Route path="article26" element={<NewsDetails26 />} />
        <Route path="article27" element={<NewsDetails27 />} />
        <Route path="article28" element={<NewsDetails28 />} />
        <Route path="article29" element={<NewsDetails29 />} />
        <Route path="article30" element={<NewsDetails30 />} />
        <Route path="article31" element={<NewsDetails31 />} />
        <Route path="article32" element={<NewsDetails32 />} />
        <Route path="article33" element={<NewsDetails33 />} />
        <Route path="article34" element={<NewsDetails34 />} />
        <Route path="article35" element={<NewsDetails35 />} />
        <Route path="article36" element={<NewsDetails36 />} />
        <Route path="article37" element={<NewsDetails37 />} />
        <Route path="article38" element={<NewsDetails38 />} />
        <Route path="article39" element={<NewsDetails39 />} />
        <Route path="article40" element={<NewsDetails40 />} />
        <Route path="article41" element={<NewsDetails41 />} />
        <Route path="article42" element={<NewsDetails42 />} />
        <Route path="article43" element={<NewsDetails43 />} />
        <Route path="article44" element={<NewsDetails44 />} />
        <Route path="article45" element={<NewsDetails45 />} />
        <Route path="article46" element={<NewsDetails46 />} />
        <Route path="article47" element={<NewsDetails47 />} />
        <Route path="article48" element={<NewsDetails48 />} />
        <Route path="article49" element={<NewsDetails49 />} />
        <Route path="article50" element={<NewsDetails50 />} />
        <Route path="article51" element={<NewsDetails51 />} />
        <Route path="article52" element={<NewsDetails52 />} />
        <Route path="article53" element={<NewsDetails53 />} />
        <Route path="article54" element={<NewsDetails54 />} />
        <Route path="article55" element={<NewsDetails55 />} />
        <Route path="article56" element={<NewsDetails56 />} />
        <Route path="article57" element={<NewsDetails57 />} />
        <Route path="article58" element={<NewsDetails58 />} />
        <Route path="article59" element={<NewsDetails59 />} />
        <Route path="LandingConfirmation" element={<LandingConfirmation />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="*" element={<p>Not found!</p>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle58 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Halfjaarlijkse wagenpark update voor Belgische accijns teruggaaf 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage58.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                17-10-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Dankzij de{" "}
            <span style={{color: "#2b5baa"}}>
            relatief lage dieselprijzen{" "}
            </span>
            en de{" "}
            <span style={{color: "#2b5baa"}}>
            gunstige ligging{" "}
            </span>
            is België een aantrekkelijk land om te tanken voor veel in Nederland gevestigde internationale transporteurs. Als internationale transporteur bent u waarschijnlijk bekend met de mogelijkheid om een{" "}
            <span style={{color: "#2b5baa"}}>
            deel van de accijnzen op energieproducten zoals diesel terug te vorderen {" "}
            </span>
            in onder andere België.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om in aanmerking te komen voor gedeeltelijke teruggaaf van accijns op diesel dient u zich te houden aan de voorschriften uit de{" "}
            <span style={{color: "#9c29ff"}}>
            handleiding "professionele diesel"
            </span>
            . Een van de vereisten is het{" "}
            <span style={{color: "#2b5baa"}}>
            tijdig doorgeven van wijzigingen in uw wagenpark
            </span>
            , met name de voertuigen die in België tanken en waarvoor u accijns teruggaaf aanvraagt. Dit wordt vaak over het hoofd gezien, maar kan leiden tot aanzienlijke{" "}
            <span style={{color: "#2b5baa"}}>
            vertragingen{" "}
            </span>
            of zelfs{" "}
            <span style={{color: "#2b5baa"}}>
            gedeeltelijke afwijzing{" "}
            </span>
            van de teruggaaf. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het is belangrijk om de volgende wijzigingen in uw wagenpark door te geven aan de dienst "professionele diesel": 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
             <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        <span style={{color: "#9c29ff"}}>
            Toevoegingen{" "}
            </span>
            van nieuwe voertuigen aan het wagenpark 
                        </li>
                        <li>   
                        <span style={{color: "#9c29ff"}}>
            Schrapping{" "}
            </span>
            van voertuigen uit het wagenpark 
                        </li>
                        <li> 
                        <span style={{color: "#9c29ff"}}>
            Wijzigingen{" "}
            </span>
            in de tenaamstelling van voertuigen 
                        </li>
                        <li> 
                        <span style={{color: "#9c29ff"}}>
            Verlenging {" "}
            </span>
            van huur- of leasecontracten van voertuigen 
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            U dient de wijzigingen{" "}
            <span style={{color: "#9c29ff"}}>
            schriftelijk per post{" "}
            </span>
            door te geven (e-mail wordt niet geaccepteerd). Voor de toevoeging van nieuwe voertuigen aan uw wagenpark stuurt u de volgende documenten mee: 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
               <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        <span style={{color: "#2b5baa"}}>
                        Een kopie van het kentekenbewijs 
            </span>
                        </li>
                        <li>   
                        (Indien van toepassing) bij een leasevoertuig:  {" "}
                        <span style={{color: "#2b5baa"}}>
                        een kopie van het leasecontract {" "}
            </span>
            (let op, alleen documenten in het Nederlands, Duits of Frans worden geaccepteerd; anders is een beëdigde vertaling nodig) 
                        </li>
                        <li> 
                        (Indien van toepassing) als het voertuig op naam staat van een andere entiteit dan waarvoor de teruggaaf wordt aangevraagd, bijvoorbeeld op naam van een holding:{" "}    
                        <span style={{color: "#2b5baa"}}>
                        een kopie van het huurcontract
            </span>
            , ondertekend door een vertegenwoordiger van beide entiteiten. 
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Wanneer u een voertuig uit uw wagenpark schrapt, vermeldt u in de brief om welk voertuig het gaat. Bij een wijziging in de tenaamstelling (bijvoorbeeld bij aankoop) voegt u een kopie van het nieuwe kentekenbewijs bij. Bij verlenging van een huur- of leasecontract vermeldt u om welk voertuig het gaat en stuurt u een kopie van het verlengde huur- of leasecontract mee. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
              De richtlijn is om{" "}
              <span style={{color: "#9c29ff"}}>
              ten minste eens per halfjaar {" "}
            </span>
            een update van uw wagenpark door te geven aan de dienst professionele diesel bij wijzigingen. Mocht u deze zorg liever niet zelf willen dragen, dan kan <a href="https://deltarefundsolutions.com/Contact"> Delta</a> u hierbij ondersteunen. Wij nemen het volledige proces voor u uit handen en communiceren namens u met de dienst professionele diesel om alle benodigde wijzigingen door te geven. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle58;

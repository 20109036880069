import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle59 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Nieuwe verplichte voertuigverklaring Scandinavië 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage59.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                08-11-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor internationale transporteurs die naar {" "}
            <span style={{color: "#2b5baa"}}>
            Scandinavië{" "}
            </span>
            vervoeren is het essentieel om op de hoogte te zijn van de laatste veranderingen in de{" "}
            <span style={{color: "#2b5baa"}}>
            voertuigverklaring vereisten
            </span>
           . Vanaf heden moet het{" "}
            <span style={{color: "#9c29ff"}}>
            nieuwe Skyttel PAN nummer {" "}
            </span>
            zichtbaar zijn op een voertuigverklaring. Dit nummer {" "}
            <span style={{color: "#9c29ff"}}>
            vervangt het oudere BroBizz PAN nummer
            </span>
            , dat op verouderde documenten nog steeds te vinden is. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De overgang naar het Skyttel PAN nummer is van belang voor een {" "}
            <span style={{color: "#2b5baa"}}>
            soepele doorgang bij tolstations en grensovergangen
            </span>
            . Hoewel documenten met een BroBizz PAN nummer momenteel nog geaccepteerd kunnen worden, wordt aangeraden om {" "}
            <span style={{color: "#9c29ff"}}>
            zo snel mogelijk over te schakelen{" "}
            </span>
            naar een bijgewerkte versie met het Skyttel PAN nummer. U kunt uw nieuwe voertuigverklaring document {" "}
            <span style={{color: "#9c29ff"}}>
            opvragen bij uw tolleverancier.            
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Op de {" "}
            <span style={{color: "#2b5baa"}}>
            Øresundbrug en in Noorwegen{" "}
            </span>
            worden zowel digitale als papieren versies van de voertuigverklaring geaccepteerd. Voor de{" "}
            <span style={{color: "#2b5baa"}}>
            Storebælt-brug{" "}
            </span>
            blijft echter een geprinte versie van het document verplicht. Zorg dus altijd in ieder geval een{" "}
            <span style={{color: "#2b5baa"}}>
            geprinte versie{" "}            
            </span>
            bij u te hebben, dit om ongewenste vertragingen en eventueel boetes te vermijden. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle59;
